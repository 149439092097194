import firebase from '@firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  "apiKey": "AIzaSyBIj6aZTlMJnmwLEik1N_mVtrZvBHbUGP4",
  "authDomain": "wedink-f4700.firebaseapp.com",
  "databaseURL": "https://wedink-f4700.firebaseio.com",
  "projectId": "wedink-f4700",
  "storageBucket": "wedink-f4700.appspot.com",
  "messagingSenderId": "97967783975"
}

export {
  firebase,
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
