import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { AppBar, Typography, Toolbar, CssBaseline, Button } from '@material-ui/core';
import { Link, navigate } from "gatsby";
import { withStyles } from '@material-ui/core/styles';
import LogoIcon from './image';
import "./layout.css";
import 'typeface-roboto';
import FirebaseClient from '../clients/FirebaseClient';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'linear-gradient(110deg, ' + theme.palette.primary.main + ' 60%, ' + theme.palette.primary.dark + ' 60%)'
  },
  toolBar: {
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.up(1200 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1200 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    flex: '1 0 auto',
    paddingBottom: 100
  },
  toolbarTitle: {
    marginLeft: 5,
  },
  footer: {
    top: 'auto',
    bottom: 0,
    boxSizing: 'border-box'
  },
  link: {
    textDecoration: 'none'
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: theme.spacing.unit * 5
  }
});

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      processing: true,
    };
  }

  componentDidMount() {
    FirebaseClient.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    });
  }

  handleSignOut() {
    FirebaseClient.auth().signOut()
    .then(() => {
      navigate('/signin');
    })
    .catch((error) => {
      alert(error);
    });
  }

  render() {
    const { children, classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar position="sticky" color="inherit" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <a className={classes.link} href="/"><LogoIcon /></a>
              <a className={classes.link} href="/" style={{flexGrow: 1}}>
                <Typography variant="h5" color="primary" noWrap className={classes.toolbarTitle}>
                  WedInk
                </Typography>
              </a>
              <Link to="/">
                <Button color="primary">Home</Button>
              </Link>
              <Link to="/onboard">
                <Button color="primary">Get Started</Button>
              </Link>
              {!this.state.user && (
                <Link to="/signin">
                  <Button color="primary">Sign In</Button>
                </Link>
              )}
              {this.state.user && (
                <span>
                  <Link to="/console">
                    <Button color="primary">Console</Button>
                  </Link>
                  <Button color="primary" onClick={() => { this.handleSignOut() }}>Sign Out</Button>
                </span>
              )}
            </Toolbar>
          </AppBar>
          <main className={classes.layout}>
            {children}
          </main>
          <AppBar color="default" className={classNames(classes.footer)}>
            <Toolbar>
              <Typography variant="caption" color="inherit" align="center" noWrap style={{ flex: 1 }}>
                © {new Date().getFullYear()},
                {` `}
                <a className={classes.link} href="/">WedInk App</a> | <a className={classes.link} href="/privacy-policy">Privacy Policy</a>
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
      </React.Fragment>
    )}
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Layout);