import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography } from "@material-ui/core";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Typography style={{ color: "#FFF" }}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Typography>
  </Layout>
)

export default NotFoundPage
